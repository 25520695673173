import React from "react"
import { Link } from "gatsby"

import AniLink from "./AniLink"

import { services } from "../assets/js/helpers"

const year = new Date().getFullYear();

function Footer() {


  const howWeWorkLink = [
    {
      url: "/how-we-work#discover",
      hash: "discover",
      title: "Discover",
    },
    {
      url: "/how-we-work#build",
      hash: "build",
      title: "Build",
    },
    {
      url: "/how-we-work#staff",
      hash: "staff",
      title: "Staff",
    },
    {
      url: "/how-we-work#support",
      hash: "support",
      title: "Support",
    },
  ]

  return (
    <div className="text-center text-lg-left">
      <footer className="section-75">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer-banner">
                {/* <Img
                  className="footer-logo"
                  // fluid={footerImages.springkrafLight.childImageSharp.fluid}
                  alt="Springkraf Footer Logo"
                /> */}
                <div className="mb-4">
                  <span className="small-caption weight-bold">INDONESIA</span>
                  <br />
                  JALAN BOULEVARD BARAT RAYA CITRALAND GAMA CITY MEDAN
                  RUKO MADISON AVENUE BLOK R8 NO 1 KEL. KENANGAN BARU KEC. PERCUT SEI TUAN
                  KAB. DELI SERDANG SUMATERA UTARA 20371
                  <br />
                  +62 852 6279 8297
                </div>
                <div className="footer-social mb-5">
                  <a className="footer-icon" target="_blank" href="mailto:hello@springkraf.com">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      <path
                        d="M17.645 20.0512C18.2947 20.0512 18.8481 19.8106 19.3373 19.3373C19.8106 18.8481 20.0512 18.2947 20.0512 17.645C20.0512 16.9954 19.8106 16.4419 19.3373 15.9527C18.8481 15.4795 18.2947 15.2389 17.645 15.2389C16.9954 15.2389 16.4419 15.4795 15.9527 15.9527C15.4795 16.4419 15.2389 16.9954 15.2389 17.645C15.2389 18.2947 15.4795 18.8481 15.9527 19.3373C16.4419 19.8106 16.9954 20.0512 17.645 20.0512ZM17.645 9.62451C19.8507 9.62451 21.7355 10.4266 23.2995 11.9906C24.8635 13.5546 25.6655 15.4394 25.6655 17.645V18.808C25.6655 19.61 25.3848 20.2918 24.8635 20.8532C24.302 21.3906 23.6604 21.6553 22.8583 21.6553C21.8959 21.6553 21.1019 21.2542 20.5003 20.4522C19.6983 21.2542 18.7518 21.6553 17.645 21.6553C16.5462 21.6553 15.5998 21.2542 14.8058 20.4843C14.0358 19.6902 13.6348 18.7518 13.6348 17.645C13.6348 16.5462 14.0358 15.5998 14.8058 14.8058C15.5998 14.0358 16.5462 13.6348 17.645 13.6348C18.7518 13.6348 19.6902 14.0358 20.4843 14.8058C21.2542 15.5998 21.6553 16.5462 21.6553 17.645V18.808C21.6553 19.1368 21.7836 19.4256 22.0242 19.6742C22.2648 19.9228 22.5455 20.0512 22.8583 20.0512C23.1952 20.0512 23.4759 19.9228 23.7165 19.6742C23.9572 19.4256 24.0614 19.1368 24.0614 18.808V17.645C24.0614 15.8885 23.4438 14.3807 22.1766 13.1134C20.9094 11.8462 19.4015 11.2286 17.645 11.2286C15.8885 11.2286 14.3807 11.8462 13.1134 13.1134C11.8462 14.3807 11.2286 15.8885 11.2286 17.645C11.2286 19.4015 11.8462 20.9094 13.1134 22.1766C14.3807 23.4438 15.8885 24.0614 17.645 24.0614H21.6553V25.6655H17.645C15.4394 25.6655 13.5546 24.8635 11.9906 23.2995C10.4266 21.7355 9.62451 19.8507 9.62451 17.645C9.62451 15.4394 10.4266 13.5546 11.9906 11.9906C13.5546 10.4266 15.4394 9.62451 17.645 9.62451Z"
                        fill="#0FBDBD"
                      />
                    </svg>
                  </a>

                  <a className="footer-icon" target="_blank" href="https://www.instagram.com/springkraf">
                    <svg
                      className="footer-icon"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="17.3191" cy="17.3191" r="17.3191" fill="white" />
                      <path
                        d="M13.9288 9.23657C11.3448 9.23657 9.23685 11.3446 9.23685 13.9285V20.7091C9.23685 23.2931 11.3448 25.401 13.9288 25.401H20.7094C23.2933 25.401 25.4013 23.2931 25.4013 20.7091V13.9285C25.4013 11.3446 23.2933 9.23657 20.7094 9.23657H13.9288ZM13.9288 10.48H20.7094C22.6206 10.48 24.1579 12.0148 24.1579 13.9285V20.7091C24.1579 22.6203 22.6231 24.1576 20.7094 24.1576H13.9288C12.0176 24.1576 10.4803 22.6228 10.4803 20.7091V13.9285C10.4803 12.0173 12.0151 10.48 13.9288 10.48ZM21.9819 11.7234C21.4671 11.7234 21.0494 12.1411 21.0494 12.656C21.0494 13.1708 21.4671 13.5885 21.9819 13.5885C22.4968 13.5885 22.9145 13.1708 22.9145 12.656C22.9145 12.1411 22.4968 11.7234 21.9819 11.7234ZM17.3191 12.9668C14.9221 12.9668 12.9671 14.9218 12.9671 17.3188C12.9671 19.7158 14.9221 21.6708 17.3191 21.6708C19.7161 21.6708 21.6711 19.7158 21.6711 17.3188C21.6711 14.9218 19.7161 12.9668 17.3191 12.9668ZM17.3191 14.2103C19.0434 14.2103 20.4276 15.5945 20.4276 17.3188C20.4276 19.0431 19.0434 20.4274 17.3191 20.4274C15.5948 20.4274 14.2105 19.0431 14.2105 17.3188C14.2105 15.5945 15.5948 14.2103 17.3191 14.2103Z"
                        fill="#0FBDBD"
                      />
                    </svg>
                  </a>

                  <a className="footer-icon" target="_blank" href="https://www.linkedin.com/company/springkraf/">
                    <svg
                      className="footer-icon"
                      width="36"
                      height="35"
                      viewBox="0 0 36 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="17.8257" cy="17.3191" r="17.3191" fill="white" />
                      <g clip-path="url(#clip0)">
                        <path d="M13.6612 15.3857H10.6846V24.3248H13.6612V15.3857Z" fill="#0FBDBD" />
                        <path
                          d="M23.4303 16.2024C22.805 15.5198 21.978 15.1785 20.9498 15.1785C20.5709 15.1785 20.2266 15.2251 19.9168 15.3183C19.6072 15.4115 19.3456 15.5423 19.1321 15.7107C18.9186 15.8791 18.7487 16.0354 18.6225 16.1797C18.5025 16.3167 18.3851 16.4763 18.2706 16.6569V15.3858H15.3028L15.3119 15.8188C15.318 16.1076 15.321 16.9975 15.321 18.4888C15.321 19.9802 15.315 21.9256 15.303 24.325H18.2706V19.3366C18.2706 19.03 18.3035 18.7865 18.3698 18.606C18.4962 18.2991 18.6869 18.0422 18.9427 17.8348C19.1984 17.6272 19.5155 17.5235 19.8944 17.5235C20.4115 17.5235 20.7919 17.7024 21.0355 18.0602C21.279 18.4179 21.4008 18.9126 21.4008 19.544V24.3247H24.3685V19.2015C24.3683 17.8844 24.0558 16.8847 23.4303 16.2024Z"
                          fill="#0FBDBD"
                        />
                        <path
                          d="M12.191 11.083C11.6919 11.083 11.2875 11.2289 10.9777 11.5205C10.668 11.8122 10.5132 12.1804 10.5132 12.6255C10.5132 13.0644 10.6635 13.4313 10.9642 13.7259C11.2648 14.0205 11.6618 14.1679 12.1549 14.1679H12.1729C12.6781 14.1679 13.0856 14.0206 13.3952 13.7259C13.7049 13.4313 13.8567 13.0645 13.8507 12.6255C13.8447 12.1804 13.6913 11.8122 13.3907 11.5205C13.0901 11.2288 12.6901 11.083 12.191 11.083Z"
                          fill="#0FBDBD"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect x="10.5132" y="10.7761" width="13.8553" height="13.8553" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div>
                  <a
                    href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
                    target="_blank"
                  >
                    <div className="d-inline button button-ghost">Chat with us</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-navigation">
                <div className="navigation-group">
                  <div className="footer-link-header">Portfolio</div>
                  <ul className="footer-link">
                    <li>
                      <AniLink to="/portfolio/top-remit">Top Remit</AniLink>
                    </li>
                    <li>
                      <AniLink to="/portfolio/f2c-furniture">F2C Furniture</AniLink>
                    </li>
                    <li>
                      <AniLink to="/portfolio/sir-salon">Sir Salon</AniLink>
                    </li>
                  </ul>
                </div>

                <div className="navigation-group">
                  <div className="footer-link-header">How We Work</div>
                  <ul className="footer-link">
                    {howWeWorkLink.map((value, key) => {
                      return (
                        <li key={key}>
                          <Link to={value.url} smooth={true} offset={-50}>
                            {value.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="navigation-group">
                  <div className="footer-link-header">Services</div>
                  <ul className="footer-link">
                    {services.map((value, key) => {
                      return (
                        <li key={key}>
                          <Link to={value.link} smooth={true} offset={-50}>
                            {value.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">Copyright © {year} {'CV Solusi Manajemen Global'.toUpperCase()}. All rights reserved.</div>
            {/* <div className="col-lg-7 d-flex justify-content-center">
              <div className="ml-lg-auto">
                Cookies <span className="mx-3">|</span>
                Privacy Policy <span className="mx-3">|</span>
                Terms <span className="mx-3">|</span>
                Continuation Policy
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
